import React, { FC, useEffect } from 'react';
import { Route } from 'react-router-dom';

import {
  urlSchema,
  RouteScheme,
  getUrl,
} from 'src/pages';

import { PageWithHeader } from 'src/app';
import { WithTranslate } from 'src/i18n';
import { Systems, SystemsContext } from 'src/constants';

import { ProjectSelectorDropDown } from 'src/modules/general/projects';
import { ShipmentsProjectsPage } from 'src/modules/shipments/projects';
import { ShipmentsCalcPage } from 'src/modules/shipments/calculations';
import { ShipmentsRoutesPage } from 'src/modules/shipments/routes';

interface Props extends WithTranslate {
  match?: any;
  onChange(): void;
}

const ShipmentsRoot: FC<Props> = (props) => {
  const {
    match,
    t,
    onChange,
  } = props;

  useEffect(() => {
    window.location.href = '/freight';
  }, []);

    return (
    <SystemsContext.Provider value={Systems.shipments}>
      <PageWithHeader
        basePath={[Systems.shipments]}
        menuRenderers={{
          projects: {
            label: t('modules.projects.captions.title'),
          },
          calculations: {
            label: t('modules.calculations.captions.title'),
          },
          routeAnalysis: {
            label: t('modules.routes.captions.title'),
          },
        }}
        headerChildren={(
          <Route path={getUrl(urlSchema.shipments.projects)}>
            {({ match: currentMatch }) => {
              if (currentMatch) return null;
              return (
                <ProjectSelectorDropDown
                  onChange={onChange}
                />
              );
            }}
          </Route>
        )}
      >
        <RouteScheme
          prevMatch={match}
          basePath={['shipments']}
          renderers={{
            projects: {
              component: ShipmentsProjectsPage,
            },
            calculations: {
              component: ShipmentsCalcPage,
            },
            routeAnalysis: {
              component: ShipmentsRoutesPage,
            },
          }}
        />
      </PageWithHeader>
    </SystemsContext.Provider>
  );
};

export default ShipmentsRoot;
